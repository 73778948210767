@import "styles/em";

.header {
    position: absolute;
    display: flex;
    align-items: center;
    height: em(100);
    padding: 0 em(240);

    h1 {
        img {
            width: em(110);
            height: em(40);
        }
    }

    @media screen and (max-width: 991px) {
        height: em(130);
        padding: 0 em(40);

        h1 {
            img {
                width: em(376);
                height: em(30);
            }
        }
    }
}
