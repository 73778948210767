@import 'styles/em';

.project-sec {
  position: relative;
  padding-top: em(121);
  padding-bottom: em(410);
  overflow: hidden;

  h2 {
    position: relative;
    padding-left: em(240, 160);
    font-size: em(160);
    letter-spacing: -0.015em;
    margin-bottom: em(150, 160);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  .circle {
    position: absolute;
    width: em(785);
    height: em(785);
    transform: rotate(-30deg);
    transform-origin: center;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      border: 10px solid transparent;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
      // background-size: 200% 200% !important;
      // animation: moveGradient 10s infinite ease both;
    }
  }

  .circle-1 {
    top: em(100);
    left: em(-200);

    &:before {
      border: 14px solid transparent;
      background: linear-gradient(
          144.59deg,
          #d143d0 23.95%,
          #5d8df6 58.22%,
          #6ed868 91.09%
        )
        border-box;
      // background: linear-gradient(
      //         144.59deg,
      //         #d143d0 11.975%,
      //         #5d8df6 29.11%,
      //         #6ed868 45.545%,
      //         #d143d0 61.975%,
      //         #5d8df6 79.11%,
      //         #6ed868 95.545%
      //     )
      //     border-box;
    }
  }

  .circle-2 {
    top: em(942);
    left: em(1144);
    opacity: 0.9;

    &:before {
      background: linear-gradient(
          110.76deg,
          #852ee7 15.65%,
          #6187f4 68.01%,
          #50c4ff 81.9%
        )
        border-box;
      // background: linear-gradient(
      //         110.76deg,
      //         #852ee7 7.825%,
      //         #6187f4 34.005%,
      //         #50c4ff 40.95%,
      //         #852ee7 57.825%,
      //         #6187f4 84.005%,
      //         #50c4ff 90.95%
      //     )
      //     border-box;
    }
  }

  .circle-3 {
    top: em(942);
    left: em(1388);

    &:before {
      background: linear-gradient(
          144.59deg,
          #d143d0 23.95%,
          #5d8df6 58.22%,
          #6ed868 91.09%
        )
        border-box;
      // background: linear-gradient(
      //         144.59deg,
      //         #d143d0 11.975%,
      //         #5d8df6 29.11%,
      //         #6ed868 45.545%,
      //         #d143d0 61.975%,
      //         #5d8df6 79.11%,
      //         #6ed868 95.545%
      //     )
      //     border-box;
    }
  }

  .carousel {
    position: relative;
    padding-left: em(240);
    padding-right: em(240);

    > div {
      margin: 0 em(-52);
    }

    .carousel-item {
      display: grid;
      grid-template-areas:
        'item-1 item-2'
        'item-1 item-3';
      grid-template-columns: 1fr 1fr;
      row-gap: em(60);
      column-gap: em(52);
      padding: 0 em(52);

      .project-item {
        display: flex;
        perspective: 1500px;

        > a {
          position: relative;
          background-color: #d9d9d9;
          height: 100%;
          flex: 1;
          cursor: pointer;
          position: relative;
          clip-path: inset(1px);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .overlay {
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(
              300.61deg,
              rgba(151, 77, 184, 0.8) 0.33%,
              rgba(85, 133, 249, 0.8) 55.64%,
              rgba(93, 207, 249, 0.8) 108.7%
            );
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: 0.3s;

            svg {
              width: em(70);
              height: em(70);
            }
          }

          &:hover {
            .overlay {
              opacity: 1;
            }
          }
        }

        &:first-child {
          height: em(820);
          grid-area: item-1;
          align-self: center;
        }

        &:nth-child(2) {
          height: em(450);
          grid-area: item-2;
        }

        &:last-child {
          height: em(450);
          grid-area: item-3;
        }
      }

      .project-name {
        writing-mode: vertical-rl;
        transform: scale(-1);
        align-self: flex-end;
        margin-right: em(20);
        font-size: em(24);
        font-family: Montserrat;
        line-height: 1;
        text-transform: uppercase;

        span {
          color: #a6a3a1;
        }
      }
    }

    .carousel-arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      background: transparent;
      border: 0;
      outline: 0;
      width: em(140);
      height: em(140);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: inherit;

      &:not(:disabled) {
        cursor: pointer;
      }

      &:disabled {
        svg {
          fill: #4f4f4f;
        }
      }

      svg {
        width: em(140);
        height: em(140);
        fill: #fff;
        transition: 0.25ms;
      }

      &.arrow-left {
        left: em(80);
      }

      &.arrow-right {
        right: em(80);
      }
    }
  }

  @media screen and (min-width: 992px) {
    .react-multi-carousel-dot-list {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    padding-top: 0;
    padding-bottom: 0;

    h2 {
      position: relative;
      padding-top: em(240, 100);
      padding-left: 0;
      font-size: em(100);
      margin-bottom: em(80, 100);
      text-align: center;
    }

    .circle-2,
    .circle-3 {
      display: none;
    }

    .carousel {
      padding-left: em(45);
      padding-right: em(45);

      > div {
        margin: 0 em(-40);
      }

      .carousel-item {
        display: grid;
        grid-template-areas:
          'item-1'
          'item-2'
          'item-3';
        grid-template-columns: 1fr;
        row-gap: em(40);
        column-gap: em(40);
        padding: 0 em(40);

        .project-item {
          // height: em(508) !important;
          aspect-ratio: 660 / 508;
          height: auto !important;
        }

        .project-name {
          display: none;
        }
      }

      .carousel-arrow {
        display: none;
      }
    }

    .react-multi-carousel-dot-list {
      position: initial;
      margin-top: em(60);
      margin-bottom: em(300);

      .react-multi-carousel-dot {
        display: inline-flex;

        button {
          width: em(14);
          height: em(14);
          border: 0;
          background-color: #4f4f4f;
        }

        &.react-multi-carousel-dot--active {
          button {
            background-color: #fff;
          }
        }
      }
    }
  }
}
