@import "styles/em";

.App {
  overflow: hidden;
}

.grid-bg {
  background-size: em(56.6) em(56.6);
  background-image: linear-gradient(to right, var(--stroke) 1px, transparent 1px),
    linear-gradient(to bottom, var(--stroke) 1px, transparent 1px);
}

@media screen and (min-width: 992px) {
  .hidden-lg {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .hidden-sm {
    display: none;
  }

  .grid-bg {
    background-size: em(750/35) em(750/35);
  }
}

@keyframes moveGradient {
  0% {
    background-position: 0% 4%;
  }
  50% {
    background-position: 100% 97%;
  }
  100% {
    background-position: 0% 4%;
  }
}
