@import "styles/em";

.development-sec {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: em(160);
    padding-left: em(240);
    padding-right: em(240);

    .top-text {
        h3 {
            font-size: em(64);
            line-height: em(90, 64);
            margin-bottom: em(46, 64);
        }

        p {
            font-size: em(30);
            line-height: em(54, 30);
        }
    }

    h2 {
        position: absolute;
        top: em(455);
        display: flex;
        flex-direction: column;
        transform: rotate(-25.25deg);

        span {
            will-change: transform;
            display: inline-block;
            font-family: Montserrat;
            font-size: em(230);
            line-height: em(256, 230);
            letter-spacing: -0.015em;
            text-transform: uppercase;

            &:first-child {
                background: linear-gradient(97.5deg, #5dcff9 9.57%, #5089fd 68.88%, #974db8 132.02%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }

            &:nth-child(2) {
                background: linear-gradient(95.21deg, #5dcff9 -3.97%, #5089fd 40.95%, #974db8 88.77%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                transform: translateX(em(-239, 230));
            }

            &:last-child {
                background: linear-gradient(99.8deg, #5dcff9 -61.07%, #5089fd 9.02%, #974db8 83.64%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                transform: translateX(em(-50, 230));
            }
        }
    }

    .bottom-text {
        margin-top: em(642, 30);
        font-size: em(30);
        line-height: em(54, 30);
        align-self: flex-end;
    }

    @media screen and (max-width: 991px) {
        padding-top: 0;
        padding-left: em(40);
        padding-right: em(40);
        padding-bottom: em(300);
        overflow: hidden;

        h2 {
            transform: rotate(-25.25deg);

            span {
                font-size: em(146.37);
                line-height: em(163, 146.37);

                &:nth-child(2) {
                    transform: translateX(em(-65, 146.37));
                }

                &:last-child {
                    transform: translateX(em(-5, 146.37));
                }
            }
        }

        .bottom-text {
            margin-top: em(755, 30);
        }
    }
}
