@import "styles/em";

.footer {
    background-color: var(--bg);
    padding: em(60) em(240) em(98);

    img {
        width: em(210);
        height: em(16);
    }

    p {
        margin-top: em(20);
        font-size: em(16);
    }

    @media screen and (max-width: 991px) {
        padding: em(60) em(45);
    }
}
