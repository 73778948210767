@import "styles/em";

.project-modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;

    .overlay-inner {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        overflow: auto;
        padding-left: em(240);
        padding-right: em(240);
        padding-top: em(120);
        padding-bottom: em(120);
        visibility: hidden;

        @media screen and (max-width: 991px) {
            padding-left: em(60);
            padding-right: em(60);
            padding-top: em(120);
            padding-bottom: em(60);
        }
    }
}

.project-modal {
    position: relative;
    background-color: #000;
    outline: 0;

    .close-btn {
        position: absolute;
        top: em(-10);
        right: em(-70);
        background-color: transparent;
        outline: 0;
        cursor: pointer;
        width: em(50);
        height: em(50);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
            width: em(40);
            height: em(40);
        }
    }

    .preview {
        background-color: #d9d9d9;
        width: 100%;
        height: em(860);
        clip-path: inset(1px);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .modal-content {
        overflow: hidden;

        .modal-content-inner {
            border: 3px solid #fff;
            border-top: 0;
            padding: em(100) em(80);

            .modal-header {
                display: flex;
                margin-bottom: em(50);

                h3 {
                    font-family: Montserrat;
                    font-size: em(60);
                    text-transform: uppercase;
                }

                span {
                    display: inline-block;
                    margin-left: em(10, 30);
                    font-size: em(30);
                    color: #a6a3a1;
                }
            }

            > p {
                white-space: pre-wrap;
                font-size: em(24);
                margin-bottom: em(60, 24);
                line-height: em(40, 24);
            }

            .modal-content-content {
                display: grid;
                grid-template-areas:
                    "image-1 text-1"
                    "text-2 image-2";
                column-gap: em(40);

                .image-1 {
                    width: em(620);
                    height: em(820);
                    background-color: #d9d9d9;
                    grid-area: image-1;
                }

                .image-2 {
                    width: em(620);
                    height: em(820);
                    background-color: #d9d9d9;
                    grid-area: image-2;
                }

                .text-1 {
                    grid-area: text-1;
                    padding-top: em(130);

                    h4 {
                        font-family: Montserrat;
                        font-size: em(30);
                        margin-bottom: em(26, 30);
                        text-transform: uppercase;
                    }

                    p {
                        font-size: em(24);
                        white-space: pre-wrap;
                        line-height: em(40, 24);
                        margin-bottom: em(60, 24);
                    }
                }

                .text-2 {
                    font-size: em(24);
                    white-space: pre-wrap;
                    line-height: em(40, 24);
                    margin-top: em(130, 24);
                    grid-area: text-2;
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        border: 1px solid #fff;

        .close-btn {
            position: absolute;
            top: em(-70);
            right: 0;
            width: em(60);
            height: em(60);
        }

        .preview {
            // height: em(394);
            height: auto;
            aspect-ratio: 660 / 508;
        }

        .modal-content {
            .modal-content-inner {
                border: 0;
                padding: em(41) em(31) em(98) em(34);

                .modal-header {
                    margin-bottom: em(23);

                    h3 {
                        font-size: em(42);
                    }

                    span {
                        margin-left: em(13, 24);
                        font-size: em(24);
                    }
                }

                > p {
                    font-size: em(22);
                    margin-bottom: em(80, 22);
                    line-height: em(35, 22);
                }

                .modal-content-content {
                    grid-template-areas:
                        "image-1"
                        "text-1"
                        "image-2"
                        "text-2";
                    column-gap: em(0);

                    .image-1,
                    .image-2 {
                        width: 100%;
                        height: em(394);
                    }

                    .text-1 {
                        grid-area: text-1;
                        padding-top: em(36);

                        h4 {
                            font-size: em(30);
                            margin-bottom: em(26, 30);
                            text-transform: uppercase;
                        }

                        p {
                            font-size: em(22);
                            margin-bottom: em(80, 22);
                            line-height: em(35, 22);
                        }
                    }

                    .text-2 {
                        font-size: em(22);
                        line-height: em(35, 22);
                        margin-top: em(42, 22);
                    }
                }
            }
        }
    }
}
