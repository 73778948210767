@import "styles/em";

.contact-sec {
    position: relative;
    display: flex;
    padding-left: em(240);
    padding-right: em(240);
    padding-bottom: em(376);

    .texts-content {
        padding-right: em(257);

        h2 {
            font-size: em(60);
            margin-bottom: em(20, 60);
            font-family: Montserrat;
        }

        p {
            font-size: em(24);
            line-height: em(39, 24);
            color: var(--text-secondary);
        }
    }

    form {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;

        h3 {
            font-size: em(30);
            line-height: em(48, 30);
            margin-bottom: em(24, 30);
            text-transform: uppercase;
        }

        .submit-container {
            position: absolute;
            bottom: 0;
            transform: translateY(100%);
            align-self: flex-end;

            a {
                display: inline-block;
                border: 0;
                outline: 0;
                background: transparent;
                color: var(--text);
                font-family: Montserrat;
                font-size: em(40);
                font-weight: 700;
                cursor: pointer;
                transition: 0.3s;
                -webkit-text-fill-color: transparent;
                background: linear-gradient(92.3deg, #5dcefa 0.965%, #964fba 49.7%, #fff 49.7%);
                background-clip: text;
                -webkit-background-clip: text;
                background-position: 100%;
                text-fill-color: transparent;
                background-size: 200% 100%;
                clip-path: inset(1px);
                z-index: 1;

                &:hover {
                    font-size: em(60);
                    background-position: 0 100%;
                    animation: scale 1s infinite;
                }
            }

            .confettis {
                position: absolute;
                top: -50%;
                left: -50%;
                transform: translateY(-50%) translateX(-31%);
                width: em(940);
                pointer-events: none;
            }
        }
    }

    @media screen and (max-width: 991px) {
        flex-direction: column;
        padding-left: em(45);
        padding-right: em(45);
        padding-bottom: em(300);

        .texts-content {
            padding-right: 0;

            h2 {
                text-align: center;
                font-size: em(100);
                margin-bottom: em(46, 100);

                span {
                    display: none;
                }
            }

            p {
                display: none;
            }
        }

        form {
            h3 {
                font-size: em(42);
                line-height: 1;
                margin-bottom: em(80, 42);
                text-align: center;
            }

            .submit-container {
                position: relative;
                transform: none;
                align-self: center;

                a {
                    font-size: em(80) !important;
                    margin-top: em(80, 80);
                    background-position: 0 100%;
                    animation: none !important;
                }

                .confettis {
                    transform: translateY(-30%) translateX(-24%);
                }
            }
        }
    }
}

@keyframes scale {
    0% {
        transform: scale(0.95);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.95);
    }
}

.form-group {
    height: em(74);
    background-color: #1e1e1e;
    width: 100%;
    border-radius: em(5);
    padding: 0 em(30);

    &:not(:last-of-type) {
        margin-bottom: em(16);
    }

    input,
    textarea {
        height: 100%;
        width: 100%;
        background-color: transparent;
        border: 0;
        outline: 0;
        padding: 0;
        font-size: em(24);
        font-family: Noto Sans KR;
        color: var(--text);

        &::placeholder {
            color: #bebebe;
        }
    }

    textarea {
        resize: none;
        padding-top: em(14);
    }

    &.textarea {
        height: em(304);
    }

    @media screen and (max-width: 991px) {
        height: em(100);
        border-radius: em(8);

        &:not(:last-of-type) {
            margin-bottom: em(30);
        }

        input,
        textarea {
            font-size: em(30);
        }

        &.textarea {
            height: em(420);
        }
    }
}
