@import-normalize;

:root {
  --bg: #141414;
  --stroke: #1d1d1d;
  --text: #ffffff;
  --text-secondary: #a1a1a6;
  --skill-label: #9696a0;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-family: "Noto Sans KR", sans-serif;
  color: var(--text);
  background-color: var(--bg);
  font-size: 1vw;
  font-weight: 500;

  @media screen and (max-width: 991px) {
    font-size: 1.8vw;
  }

  @media screen and (max-width: 767px) {
    font-size: 2.64vw;
  }
}

html,
body,
h1,
h2,
h3,
a,
p,
span,
strong,
ul,
ol,
li,
div,
section,
main,
nav,
header,
footer,
img,
video,
form,
label,
button {
  margin: 0;
  padding: 0;
  border: 0;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
}

a {
  color: #fff;
  text-decoration: none;
}

footer,
header,
nav,
section {
  display: block;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

:focus:not(:focus-visible) {
  outline: none;
}
