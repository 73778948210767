@import "styles/em";

.skills-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: em(345);
    padding-bottom: em(80);
    padding-left: em(240);
    padding-right: em(266);

    .texts-content {
        h2 {
            font-size: em(60);
            font-family: Montserrat;
            margin-bottom: em(20, 60);
        }

        p {
            font-size: em(24);
            line-height: em(39, 24);
            color: var(--text-secondary);
        }
    }

    ul {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        column-gap: em(30);
        row-gap: em(50);

        li {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            height: em(86);

            @media screen and (min-width: 992px) {
                &:hover {
                    img {
                        animation: scale-logo 1s infinite;
                    }
                }
            }

            img {
                width: em(60);
                height: em(60);
                margin-bottom: em(10);
            }

            span {
                position: absolute;
                bottom: 0;
                white-space: nowrap;
                font-family: Montserrat;
                font-size: em(16);
                color: var(--skill-label);
            }
        }
    }

    @media screen and (max-width: 991px) {
        padding-top: 0;
        padding-bottom: em(60);
        padding-left: em(125);
        padding-right: em(125);
        flex-direction: column;

        .texts-content {
            h2 {
                font-size: em(100);
                margin-bottom: em(80, 100);
            }

            p {
                display: none;
            }
        }

        ul {
            grid-template-columns: repeat(4, 1fr);
            column-gap: em(60);
            row-gap: em(60);

            li {
                height: em(114);

                img {
                    width: em(80);
                    height: em(80);
                }

                span {
                    font-size: em(24);
                }
            }
        }
    }
}

@keyframes scale-logo {
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.9);
    }
}
