@import 'styles/em';

.cover-sec {
  z-index: 1;

  @media only screen and (max-height: 575.98px) and (orientation: landscape) {
    min-height: 70vw;
  }

  > div {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: em(1280);
    height: em(100);
  }

  @media screen and(max-width:991px) {
    h2 {
      z-index: 1;
    }

    svg {
      width: em(600);
      height: em(220);
    }
  }
}
