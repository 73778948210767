@import 'styles/em';

.space-sec {
  margin-top: -100vh;

  @media screen and (min-width: 992px) {
    opacity: 0;
  }

  @media screen and (max-width: 991px) {
    margin-top: -34.256vh;
  }

  @media only screen and (max-height: 575.98px) and (orientation: landscape) {
    min-height: 100vw;
  }

  > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    background-color: var(--bg);

    h2 {
      position: relative;
      font-size: em(160);
      font-family: Montserrat;
      letter-spacing: -0.015em;
      text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
      z-index: 5;
    }

    .ellipse {
      position: absolute;
      transform-origin: left;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        border: 14px solid transparent;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box,
          linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }

    .ellipse-1 {
      top: -7.5%;
      left: -8.05%;
      width: em(1114.56);
      height: em(424.45);
      transform: rotate(12.58deg);

      &:before {
        background: linear-gradient(
            110.76deg,
            #de8e6d 15.65%,
            #d86edd 45.84%,
            #50c4ff 81.9%
          )
          border-box;
      }
    }

    .ellipse-2 {
      z-index: 2;
      left: -14.35%;
      top: 14.26%;
      width: em(953);
      height: em(427.25);
      transform: rotate(12.58deg);

      &:before {
        background: linear-gradient(
            110.76deg,
            #87dbf4 15.65%,
            #737df5 45.84%,
            #a34b98 81.9%
          )
          border-box;
      }
    }

    .ellipse-3 {
      left: -14.97%;
      top: 18.52%;
      width: em(953);
      height: em(362.16);
      transform: rotate(32.86deg);

      &:before {
        background: linear-gradient(
            110.76deg,
            #de8e6d 15.65%,
            #d86edd 45.84%,
            #50c4ff 81.9%
          )
          border-box;
      }
    }

    .ellipse-4 {
      z-index: 2;
      left: -26.26%;
      top: 37.78%;
      width: em(1042.91);
      height: em(471.4);
      transform: rotate(32.86deg);

      &:before {
        background: linear-gradient(
            110.76deg,
            #de8e6d 15.65%,
            #d86edd 45.84%,
            #50c4ff 81.9%
          )
          border-box;
      }
    }

    .ellipse-5 {
      left: -23.87%;
      top: 51.85%;
      width: em(1042.91);
      height: em(471.4);
      transform: rotate(32.86deg);

      &:before {
        background: linear-gradient(
            110.76deg,
            #de8e6d 15.65%,
            #d86edd 45.84%,
            #50c4ff 81.9%
          )
          border-box;
      }
    }

    @media screen and (max-width: 991px) {
      // height: initial;
      overflow: visible;
      padding-left: em(35);
      padding-right: em(35);
      padding-top: 0;
      padding-bottom: 0;

      h2 {
        font-size: em(116);
      }

      .ellipse-1 {
        top: 3.6967vh;
        left: em(-432.68);
        width: em(1353.86);
        height: em(515.48);
      }

      .ellipse-2 {
        top: 21.2559vh;
        left: em(-565.51);
        width: em(1157.61);
        height: em(518.88);
      }

      .ellipse-3 {
        top: 24.7062vh;
        left: em(-580.04);
        width: em(1157.55);
        height: em(439.85);
      }

      .ellipse-4,
      .ellipse-5 {
        display: none;
      }
    }
  }
}
